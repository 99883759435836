import { Route, Routes, Link, HashRouter, BrowserRouter } from "react-router-dom";
import ProtectedRoutes from "../../../Routes/ProtectedRoute";
import { 
    Auth, 
    Dashboard, 
    Users, 
        UsersAdd, 
        UsersEdit, 
        UsersEditStatus, 
        UsersResetPassword, 
    Roles, 
        RoleAdd, 
        RoleEdit, 
        RolePrivilege, 
    Karyawan, 
        KaryawanAdd, 
        KaryawanEdit, 
        KaryawanPayroll, 
        KaryawanReport, 
        KaryawanNonAktif, 
        KaryawanUpload, 
        KaryawanDetail, 
    Pinjaman, 
        PinjamanAdd, 
        PinjamanApprove, 
        PinjamanPelunasan,
    Reimburse, 
        ReimburseAdd, 
        ReimburseApprove, 
    Penggajian, 
        PenggajianAdd, 
        PenggajianPPH21, 
        PenggajianAbsensi, 
        PenggajianKomponen, 
        PenggajianDetail, 
        PenggajianClosing, 
        PenggajianCancel, 
        PenggajianReport, 
        PenggajianPPHReport, 
        PenggajianBPJSReport, 
        PenggajianSendSlip, 
    THR, 
        THRAdd,
        THRCancel,
        THRApprove,
        THRUpload,
        THRReport,
        THRPPH21,
    Jabatan, 
        JabatanAdd, 
        JabatanEdit,
        JabatanUpload,
    Cabang, 
        CabangAdd, 
        CabangEdit,
    KomponenGaji, 
        KomponenGajiAdd, 
        KomponenGajiEdit,
        KomponenGajiDownload,
    Ptkp, 
        PtkpAdd, 
        PtkpEdit,
    Holiday, 
        HolidayAdd, 
        HolidayEdit,
    Parameter,
    Sinkronisasi,
    SlipGaji,
    Error 
} from "../../../Pages";
    // , Kota, Cabang, CabangAdd, CabangEdit, CabangDelete, Jabatan, JabatanAdd, JabatanEdit, 
    //     Agama, AgamaAdd, AgamaEdit, Departemen, DepartemenAdd, DepartemenEdit, KomponenGaji, KomponenGajiAdd, KomponenGajiEdit, Ptkp, PtkpAdd, PtkpEdit, Pendidikan, PendidikanAdd, PendidikanEdit, 
    //     LevelJabatan, LevelJabatanAdd, LevelJabatanEdit, StatusKaryawan, StatusKaryawanAdd, StatusKaryawanEdit, GolonganDarah, GolonganDarahAdd, GolonganDarahEdit, StatusPernikahan, StatusPernikahanAdd, StatusPernikahanEdit, JenisIdentitas, JenisIdentitasAdd, JenisIdentitasEdit, 
    //     TimeOff, TimeOffAdd, TimeOffEdit, TimeOffDelete, Lembur, LemburAdd, LemburEdit, LemburDelete, LemburApprove, ApproveTimeOff, ApproveTimeOffProcess, ApproveLembur, ApproveLemburProcess, Absensi, AbsensiUpload, Error } from "../../../Pages";

const Content = () => {
    return (
        <Routes>
            <Route path="/login" element={<Auth />} />
            <Route element={<ProtectedRoutes />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/user/index/:id" element={<Users />} />
                    <Route path="/user/add" element={<UsersAdd />} />
                    <Route path="/user/edit/:id" element={<UsersEdit />} />
                    <Route path="/user/editstatus/:id" element={<UsersEditStatus />} />
                    <Route path="/user/reset/:id" element={<UsersResetPassword />} />
                <Route path="/role/index/:id" element={<Roles />} />
                    <Route path="/role/add" element={<RoleAdd />} />
                    <Route path="/role/edit/:id" element={<RoleEdit />} />
                    <Route path="/role/privilege/:id" element={<RolePrivilege />} />
                <Route path="/datakaryawan/index/:id" element={<Karyawan />} />
                    <Route path="/datakaryawan/add" element={<KaryawanAdd />} />
                    <Route path="/datakaryawan/edit/:id" element={<KaryawanEdit />} />
                    <Route path="/datakaryawan/payroll/:id" element={<KaryawanPayroll />} />
                    <Route path="/datakaryawan/nonaktif/:id" element={<KaryawanNonAktif />} />
                    <Route path="/laporan_karyawan/index/:id" element={<KaryawanReport />} />
                    <Route path="/datakaryawan/upload" element={<KaryawanUpload />} />
                    <Route path="/datakaryawan/detail/:id" element={<KaryawanDetail />} />
                <Route path="/reimburse/index/:id" element={<Reimburse />} />
                    <Route path="/reimburse/add" element={<ReimburseAdd />} />
                    <Route path="/reimburse/approve/:id" element={<ReimburseApprove />} />
                <Route path="/pinjaman/index/:id" element={<Pinjaman />} />
                    <Route path="/pinjaman/add" element={<PinjamanAdd />} />
                    <Route path="/pinjaman/approve/:id" element={<PinjamanApprove />} />
                    <Route path="/pinjaman/pelunasan/:id" element={<PinjamanPelunasan />} />
                <Route path="/penggajian/index/:id" element={<Penggajian />} />
                    <Route path="/penggajian/add" element={<PenggajianAdd />} />
                    <Route path="/penggajian/pph21" element={<PenggajianPPH21 />} />
                    <Route path="/penggajian/upload_absensi" element={<PenggajianAbsensi />} />
                    <Route path="/penggajian/upload_komponen" element={<PenggajianKomponen />} />
                    <Route path="/penggajian/detail/:id" element={<PenggajianDetail />} />
                    <Route path="/penggajian/closing/:id" element={<PenggajianClosing />} />
                    <Route path="/penggajian/cancel/:id" element={<PenggajianCancel />} />
                    <Route path="/penggajian/kirim/:id" element={<PenggajianSendSlip />} />
                    <Route path="/penggajian/report/:id" element={<PenggajianReport />} />
                    <Route path="/penggajian/report_pph/:id" element={<PenggajianPPHReport />} />
                    <Route path="/penggajian/report_bpjs/:id" element={<PenggajianBPJSReport />} />
                <Route path="/jabatan/index/:id" element={<Jabatan />} />
                    <Route path="/jabatan/add" element={<JabatanAdd />} />
                    <Route path="/jabatan/edit/:id" element={<JabatanEdit />} />
                    <Route path="/jabatan/upload" element={<JabatanUpload />} />
                <Route path="/cabang/index/:id" element={<Cabang />} />
                    <Route path="/cabang/add" element={<CabangAdd />} />
                    <Route path="/cabang/edit/:id" element={<CabangEdit />} />
                <Route path="/komponengaji/index/:id" element={<KomponenGaji />} />
                    <Route path="/komponengaji/add" element={<KomponenGajiAdd />} />
                    <Route path="/komponengaji/edit/:id" element={<KomponenGajiEdit />} />
                    <Route path="/komponengaji/download" element={<KomponenGajiDownload />} />
                <Route path="/ptkp/index/:id" element={<Ptkp />} />
                    <Route path="/ptkp/add" element={<PtkpAdd />} />
                    <Route path="/ptkp/edit/:id" element={<PtkpEdit />} />
                <Route path="/holiday/index/:id" element={<Holiday />} />
                    <Route path="/holiday/add" element={<HolidayAdd />} />
                    <Route path="/holiday/edit/:id" element={<HolidayEdit />} />
                <Route path="/thr/index/:id" element={<THR />} />
                    <Route path="/thr/add" element={<THRAdd />} />
                    <Route path="/thr/cancel/:id" element={<THRCancel />} />
                    <Route path="/thr/approve/:id" element={<THRApprove />} />
                    <Route path="/thr/upload/:id" element={<THRUpload />} />
                    <Route path="/thr/report/:id" element={<THRReport />} />
                    <Route path="/thr/pph21/:id" element={<THRPPH21 />} />
                <Route path="/parameter/index" element={<Parameter />} />
                <Route path="/sinkronisasi/index" element={<Sinkronisasi />} />
                <Route path="/slip_gaji/index/:id" element={<SlipGaji />} />
                {/* 
                    <Route path="/cabang/delete/:id" element={<CabangDelete />} />
                <Route path="/kota/index/:id" element={<Kota />} />
                
                <Route path="/agama/index/:id" element={<Agama />} />
                    <Route path="/agama/add" element={<AgamaAdd />} />
                    <Route path="/agama/edit/:id" element={<AgamaEdit />} />
                <Route path="/departemen/index/:id" element={<Departemen />} />
                    <Route path="/departemen/add" element={<DepartemenAdd />} />
                    <Route path="/departemen/edit/:id" element={<DepartemenEdit />} />
                
                
                
                <Route path="/pendidikan/index/:id" element={<Pendidikan />} />
                    <Route path="/pendidikan/add" element={<PendidikanAdd />} />
                    <Route path="/pendidikan/edit/:id" element={<PendidikanEdit />} />
                <Route path="/leveljabatan/index/:id" element={<LevelJabatan />} />
                    <Route path="/leveljabatan/add" element={<LevelJabatanAdd />} />
                    <Route path="/leveljabatan/edit/:id" element={<LevelJabatanEdit />} />
                <Route path="/statuskaryawan/index/:id" element={<StatusKaryawan />} />
                    <Route path="/statuskaryawan/add" element={<StatusKaryawanAdd />} />
                    <Route path="/statuskaryawan/edit/:id" element={<StatusKaryawanEdit />} />
                <Route path="/golongandarah/index/:id" element={<GolonganDarah />} />
                    <Route path="/golongandarah/add" element={<GolonganDarahAdd />} />
                    <Route path="/golongandarah/edit/:id" element={<GolonganDarahEdit />} />
                <Route path="/statuspernikahan/index/:id" element={<StatusPernikahan />} />
                    <Route path="/statuspernikahan/add" element={<StatusPernikahanAdd />} />
                    <Route path="/statuspernikahan/edit/:id" element={<StatusPernikahanEdit />} />
                <Route path="/jenisidentitas/index/:id" element={<JenisIdentitas />} />
                    <Route path="/jenisidentitas/add" element={<JenisIdentitasAdd />} />
                    <Route path="/jenisidentitas/edit/:id" element={<JenisIdentitasEdit />} />
                    <Route path="/jenisidentitas/index/:id" element={<JenisIdentitas />} />
                <Route path="/timeoff/index/:id" element={<TimeOff />} />
                    <Route path="/timeoff/add" element={<TimeOffAdd />} />
                
                    <Route path="/timeoff/edit/:id" element={<TimeOffEdit />} />
                    <Route path="/timeoff/delete/:id" element={<TimeOffDelete />} />
                    <Route path="/timeoff/approve/:id" element={<TimeOffAdd />} />
                <Route path="/lembur/index/:id" element={<Lembur />} />
                    <Route path="/lembur/add" element={<LemburAdd />} />
                    <Route path="/lembur/edit/:id" element={<LemburEdit />} />
                    <Route path="/lembur/delete/:id" element={<LemburDelete />} />
                    <Route path="/lembur/approve/:id" element={<LemburApprove />} />
                <Route path="/approvetimeoff/index/:id" element={<ApproveTimeOff />} />
                    <Route path="/approvetimeoff/approve/:id" element={<ApproveTimeOffProcess />} />
                <Route path="/approvelembur/index/:id" element={<ApproveLembur />} />
                    <Route path="/approvelembur/approve/:id" element={<ApproveLemburProcess />} />
                <Route path="/lemburtimeoff/index/:id" element={<ApproveLembur />} />
                    <Route path="/lemburtimeoff/approve/:id" element={<ApproveLemburProcess />} />
                <Route path="/absensi/index/:id" element={<Absensi />} />
                    <Route path="/absensi/upload" element={<AbsensiUpload />} /> */}
            </Route>
            <Route path="*" element={<Error />} />
        </Routes>
    );
};

export default Content;
